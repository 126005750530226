import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Ul = styled.ul`
  display: none;

${MEDIA.TABLET`
    list-style: none;
    display: flex;
    z-index: 99;
    flex-flow: row nowrap;
    li {
      padding: 18px 10px;
      };
    li a {
      color: #ffffff;
      };
    flex-flow: column nowrap;
    background-color: #000000;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

`};

`;
