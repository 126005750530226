import React from 'react';
import { Link } from 'gatsby';
import { Ul } from './rightnav.css'
import PropTypes from 'prop-types';
import Pdf from 'components/documents/truett_hilliardResume1.pdf';


const RightNav = ({ open }) => {
    return (
        <Ul open={open}>
            <li>
                <Link to="/about">
                    About
                </Link>
            </li>
            <li>
                <Link to="/projects">
                    Projects
                </Link>
            </li>
            <li>
                <a
                    href={Pdf}
                    target="_blank"
                    rel="noreferrer noopener "
                >
                    Resume
                </a>
            </li>
            <li>
                <a
                    href="https://github.com/thilliard11"
                    target="_blank"
                    rel="noreferrer noopener "
                >
                    Github
                </a>
            </li>
            <li>
                <a
                    href="https://www.linkedin.com/in/truett-hilliard/"
                    target="_blank"
                    rel="noreferrer noopener "
                >
                    LinkedIn
                </a>
            </li>
        </Ul>
    )
}

RightNav.propTypes = {
    open: PropTypes.func.isRequired
};

export default RightNav