import React, { useState } from 'react';
import RightNav from './rightnav';
import { StyledBurger } from './burger.css'

const Burger = () => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <StyledBurger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </StyledBurger>
            <RightNav open={open} />
        </>
    )
};

export default Burger;